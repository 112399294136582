import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useIndividualBlog from '../../network/hooks/useGetIndividualBlog';
import parse from "html-react-parser";
import moment from 'moment';

const BlogContentPage = () => {
  const { slug } = useParams();

  const { request: getBLog } = useIndividualBlog();

  const [blogData, setBlogData] = useState();
  const [blogBody, setBlogBody] = useState();

  const handleGetBlog = async (blog_slug) => {
    const { data, error } = await getBLog(blog_slug);

    if (data && data.success) {
      setBlogData(data?.data);
    }
    if (error) {
      window.alert(error?.response?.data?.message)
    }
  };

  const handleBlogBody = () => {
    let newBlogBody = blogData?.body.replaceAll("<p><br></p>", "")
    // newBlogBody = newBlogBody?.replaceAll("<span>", "");
    // newBlogBody = newBlogBody?.replaceAll("</span>", "");
    newBlogBody = newBlogBody?.replaceAll("<p>", "<p className='body-sm text-[#6C757D] mb-5 text-justify'>");
    newBlogBody = newBlogBody?.replaceAll("<h2>", "<h2 className='heading-h4 font-medium mb-3'>");
    newBlogBody = newBlogBody?.replaceAll("<ul>", "<ul className='list-disc list-inside mb-5'>");
    newBlogBody = newBlogBody?.replaceAll("<ol>", "<ol className='list-disc list-inside mb-5'>");
    newBlogBody = newBlogBody?.replaceAll("<li>", "<li className='body-sm mb-1 text-justify'>");
    newBlogBody = newBlogBody?.replaceAll("<img", "<img className='w-full h-[450px] object-cover my-12 rounded-3xl  blogs-content-banner02'");

    setBlogBody(newBlogBody);
  };

  useEffect(() => {
    handleBlogBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogData])

  useEffect(() => {
    handleGetBlog(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='blog-content-wrapper'>
      {/* banner */}
      <div className='container py-28'>
        <div className='body-xs text-T08 mb-5'>{moment(blogData?.published_at).format("DD.MMM.YYYY")}</div>
        <h1 className='heading-h4 text-T09 max-w-[530px] w-full mb-6'>{blogData?.title}</h1>
        <div className='body-xs text-T08 pb-6 border-b border-S01'>{blogData?.summary ?? ""}</div>
        <div className='flex items-center gap-12 mt-4'>
          <div className='body-xs text-T08'>{blogData?.author_name ?? "CargoXB"}</div>
          <div className='body-xs text-T08'>{blogData?.read_time}</div>
        </div>
      </div>

      {/* banner Image */}
      <div>
        <img className=' w-full object-cover h-[500px]' src={blogData?.featured_image} alt="" />
      </div>

      <div className='container py-24'>
        {blogBody &&
          <div>{parse(blogBody)}</div>
        }
      </div>
    </div>
  )
}

export default BlogContentPage