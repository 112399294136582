import React from 'react'

const TermsAndConditions = () => {
  return (
    <div className="termsandcondition-wrappper">
      <div className='pt-20 pb-12 bg-M02'>
        <h1 className="heading-h3 mb-30 text-center text-T01">Terms of Use</h1>
      </div>

      <div className="container py-16">
        <div>
          <p className='body-md mb-5'>Welcome to our Terms of Use (“Agreement”, “Terms and Conditions”, “Terms of Use” and “Terms of Service” are names all used to refer to this document). It is necessary for you to read this carefully and agree to all terms herein before you start using the Platform (defined herein below). You can reach us at Support@CargoXB.com if you have any queries with respect to the use of the Platform.</p>

          <p className='body-md mb-10'>This Terms of Service govern the use and access of the Platform and the Products and Services provided thereunder (defined hereinbelow). This Terms of Service constitutes the legal and binding agreement between Customer (defined hereinbelow) and CargoXB (defined hereinbelow) with respect to Customer’s use of the Platform. This Agreement comes into effect on the date the Customer clicks the “Register” or any other button in the course of opening an online account with CargoXB (the “Commencement Date”).</p>

          <p className='body-md mb-5'>These capitalized terms shall have the following meanings ascribed to them:</p>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>API</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means application programming interface.</div>
        </div>
        <div className='flex border border-b-0  border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Business Day</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means a day other than a Saturday, Sunday or public holiday.</div>
        </div>
        <div className='flex border border-b-0  border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Authorized User</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means the date on which the Customer executes the Agreement with CargoXB.</div>
        </div>
        <div className='flex border border-b-0  border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Commencement Date</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means the date on which the Customer executes the Agreement with CargoXB.</div>
        </div>
        <div className='flex border border-b-0  border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Confidential Information</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means any software, data, technical, business, financial, operational, customer, vendor, or other information disclosed by a party or its affiliates, employees, directors, officers, advisors, consultants, subcontractors, or representatives to the other party, whether in writing, orally, visually, or through any other means, before or after the Commencement Date. However, Confidential Information excludes information that: (a) is obtained from a non-confidential source not bound by any confidentiality agreement or obligation of secrecy; (b) becomes widely known to the public through means other than a breach of this Agreement; (c) is independently developed by a party without reference to the other party's Confidential Information; or (d) is disclosed with prior written express approval from the disclosing party.</div>
        </div>
        <div className='flex border border-b-0  border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Customer</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>shall include any person, firm, company, or entity that is engaged in the business of importing, exporting and freight forwarding and subscribes to the Platform of CargoXB.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Customer Data</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means electronic or digital content, data and information input into the Platform by the Customer/User or collected through the Platform by the Customer/User.</div>
        </div>
        <div className='flex border border-b-0  border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Documentation</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means any user guides, manuals, handbooks, materials, instructions, and specifications made available by CargoXB to the Customer for using the Platform.</div>
        </div>
        <div className='flex border border-b-0  border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>First Level Support</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means initial support and maintenance services provided to a Customer or Authorised User.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Intellectual Property</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>covers ideas, concepts, creations, discoveries, domain names, inventions, improvements, know-how, trade or business secrets, patents, copyright (including designs and moral rights), trademarks, service marks, designs, utility models, tools, devices, methods, procedures, workflows, systems, algorithms, works of authorship, flowcharts, drawings, source codes, object codes, electronic codes, proprietary techniques, research projects, confidential and proprietary information, computer programming code, databases, images, audio, video, software programs, data, documents, instruction manuals, records, memoranda, notes, user guides, in either printed or machine-readable form, regardless of copyrightability or patentability, and any written or verbal instructions or comments.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Personal Data</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means any information relating to an identified or identifiable natural person that is processed by CargoXB as a result of, or in connection with, the provision of the services on the Platform; an identifiable natural person can be identified, directly or indirectly, in particular by reference to an identifier such as a name, identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Platform Fees</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means the total fees payable by the Customer to CargoXB which includes Subscription Fees, Customization Fees, Support Fees, and any other applicable fees.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Products and Services</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means the provision of the Platform for subscription, customization, and support including tools, software, channels, data feeds, computer programs, applications and services offered or designed by CargoXB.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>CargoXB</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>refers to Platformedge Infotech Private Limited, a company incorporated under the Companies Act, 2013 and having a registered office at Prop. No. 2315, Ground Floor, Shop Number 20, Clock Tower Subzi Mandi, Delhi-110017.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>CargoXB Brand</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means and refers to any/all logos, signs, symbols, designs, icons, terms, product names, brand names, trademarks, wordmarks, copyrights owned or under application process for registration anywhere in the world by CargoXB including content in text, images, audio, video formats in any language used on websites, portals, products owned or published by CargoXB and all promotional materials and content used by CargoXB for marketing, advertising, business promotion, sales or public relations; in either printed or machine-readable form, whether or not copyrightable or patentable.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>CargoXB Platform or Platform</div>
          <div className='label-xs  w-[60%] sm:w-[80%] items-center justify-start p-6'>means CargoXB’s website available at <a href='https://www.CargoXB.com'>www.CargoXB.com</a></div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>CargoXB Support</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means the provision of a response to problems by CargoXB through the support channels provided on the Platform.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Subscription Fees</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>the fees payable by the Customer as determined at the time of execution of the Platform Subscription Agreement.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Platform Subscription Agreement</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means the platform subscription agreement executed between the Customer and CargoXB</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Term</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means the time period which starts on the Commencement Date and continues up to the date the account of a Customer is deleted by the Customer or terminated by CargoXB.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Third-Party Application</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means any application or service developed by any person, firm, company, or entity other than CargoXB or the Customer.</div>
        </div>
        <div className='flex border border-b-0 border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Visitor</div>
          <div className='label-xs  w-[60%] sm:w-[80%] flex items-center justify-start p-6'>means any person or entity who visits or accesses the Website.</div>
        </div>
        <div className='flex border border-T01 text-T06'>
          <div className='label-xs  border-e border-T01 w-[40%] sm:w-[20%]  flex sm:items-center justify-center text-center p-6'>Website</div>
          <div className='label-xs  w-[60%] sm:w-[80%] items-center justify-start p-6'>means the website that is owned, operated, and managed by CargoXB at <span><a href='https://www.CargoXB.com'>www.CargoXB.com</a></span>.</div>
        </div>

        {/* ACCEPTANCE */}
        <div className='mt-10'>
          <div className='flex flex-col gap-5 text-black'>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 1.</div> ACCEPTANCE</div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div> The Customer acknowledges that it has read the Terms of Service, understands them, and agrees to be bound by the terms as mentioned in this Terms of Service. The person accepting this Agreement on the Customer's behalf through the registration process on CargoXB’s Platform represents that he has the authority to bind the Customer to this Terms of Service.</p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div> The Customer further acknowledges that this Agreement does not have to be signed, physically or digitally, in order to be binding. The Customer will have indicated its assent to the terms of the Agreement by clicking on the "Register" or “Sign In” or similar button that is presented to the Customer at the time of its opening or accessing of an online account with CargoXB.</p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 2.</div> THE PLATFORM</div>
            <div className='flex body-md text-T06'>
              About our Platform:
            </div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div> CargoXB owns and operates an online web-based platform by the name of CargoXB which facilitates interaction among Freight Forwarders, Exporters and Importers having valid licenses from appropriate authorities upon registration on the Platform.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div> The Platform is essentially for Importers, Exporters and Freight Forwarders who wish to avail the services of each other and for which the Platform acts as a facilitator. For the purpose of this Agreement,
            </p>
            <p className='flex mb-0 body-md text-T06'><li></li>Importer shall mean an entity having a valid Importer-Exporter Code issued by the Director General of Foreign Trade;
            </p>
            <p className='flex mb-0 body-md text-T06'><li></li>Exporter shall mean an entity having a valid Importer-Exporter Code issued by the Director General of Foreign Trade; and
            </p>
            <p className='flex mb-0 body-md text-T06'><li></li>Freight Forwarder shall mean an entity engaged in the business of receiving and shipping goods on behalf of other entities.
            </p>

            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>Use of the Platform: During the Term, the Customer may access or use the Platform, subject to:
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>the terms as mentioned in the Platform Subscription Agreement; and
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 3.</div>the terms mentioned herein in this Terms of Use.
            </p>

            <div className='flex body-md text-T06'>
              In case of discrepancies between the terms of the Platform Subscription Agreement and the Terms of Use, the terms of the Platform Subscription Agreement would prevail.
            </div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>Use of Documentation: The Customer may use and reproduce the Documentation only to the extent as may be necessary to support the Customer’s or Authorised User’s interactions with and use of the  Platform.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>Revisions to the Platform: CargoXB may at any time, with or without notice, revise any of the features and functions that it provides in its Platform, including removing such features and functions completely. However, it is agreed by the Authorised User, where it is accessing the Platform, as an employee, service provider, customer, client or agent of a Customer, that the terms, and conditions of the Platform Subscription Agreement between the relevant Customer and CargoXB shall apply.
            </p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 3.</div>TERMS OF PAYMENT AND FEES</div>

            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>The Customer shall pay to CargoXB the applicable Platform Fees in lieu of the access to the Platform and use of the Products and Services in accordance with the terms of the Platform Subscription Agreement and this Agreement.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>Unless specified in the Platform Subscription Agreement, the: (a) Platform Fees paid are non-refundable and payment obligations are non-cancellable; (b) where CargoXB chooses to modify the Platform Fees in its sole discretion, the Platform Fees payable by You shall be the Platform Fees as identified specifically in the Platform Subscription Agreement.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 3.</div>The Customer authorizes CargoXB to charge an amount equal to the Platform Fees on a monthly/quarterly/annual basis, to the credit card and/or bank accounts as may be specified in the Platform Subscription Agreement in advance of the provision of Services to the Customer by means of automatic debit or credit card charge for the duration the Term. The Customer hereby agrees to keep such valid credit card and/or bank account in effect with sufficient credit limit to enable CargoXB to charge the Platform Fees every month/quarter/annual period, as applicable, and not to challenge such charges or to request reversal of such charges. CargoXB will not be required to refund the Platform Fees under any circumstances.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 4.</div> Unless otherwise stated, CargoXB’s Platform Fees do not include any taxes, levies, duties, or similar governmental assessments of any nature, including but not restricted to value-added, sales and use, or withholding taxes, assessable by any local, state, provincial, federal, or foreign jurisdiction (collectively, "Taxes"). The Customer is responsible for paying all Taxes associated with its purchases hereunder. If CargoXB has the legal obligation to pay or collect Taxes for which Customer is responsible under this paragraph, Customer shall indemnify CargoXB for such taxes and the appropriate amount shall be invoiced to and paid by Customer, unless Customer provides CargoXB with a valid tax exemption certificate authorized by the appropriate taxing authority.
            </p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 4.</div> CUSTOMER’S RESPONSIBILITIES AND RESTRICTIONS</div>

            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>The Customer shall not:
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>Misuse the Platform or authorize any third party to do so;
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 3.</div>Provide Platform passwords and other login credentials to any third party and enable them to access or derive benefit out of the Platform;
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 4.</div>Share the non-public features of the Platform with any third party;
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 5.</div>Access the Platform in order to build a similar or competitive product or service, and take or copy ideas from CargoXB Brand including, but not limited to graphics, designs, features, and functions.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 6.</div>In the event of a breach or where it is suspected that there is a breach of this Article 4.1, CargoXB may suspend Customer’s access to the Platform and/or the Website, with or without advance notice and without prejudice to the other remedies that CargoXB may have.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 7.</div>The Customer shall take all reasonable steps to prevent unauthorized access to the Platform including but not limited to protecting the passwords and other login credentials.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 8.</div>The Customer shall notify CargoXB of any suspected unauthorized use of the Platform immediately or as soon as reasonably possible and shall use reasonable means to stop the said breach.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 9.</div>In its use of the Platform, the Customer shall follow all applicable laws and regulations including, but not limited to laws governing the protection of Personal Data and other laws applicable to the protection of Customer Data.
            </p>


            <div className='flex body-lg font-semibold'><div className='pr-1'> 5.</div>CUSTOMER’S RESPONSIBILITY</div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>Authorised User’s use of and interactions with the Platform and the Website that would violate the requirements of this Agreement attributable to the Customer.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>Any use of the Platform through the Customer’s account, whether authorized or unauthorized.
            </p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 6.</div>CUSTOMER DATA AND PRIVACY</div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>Use of Customer Data:
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>Unless CargoXB receives the prior consent of the Customer, CargoXB (a) shall not access, process, or otherwise use Customer Data other than as may be necessary to perform, operate or facilitate the Platform, to provision and provide Products and Services and to improve the overall customer experience on the Platform; (b) shall not intentionally grant any third-party access to Customer Data, including but not limited to, other Customers, except subcontractors that are subject to a reasonable non-disclosure agreement.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 3.</div>Notwithstanding the foregoing, CargoXB may disclose Customer Data as required by applicable law or by proper legal or governmental authority. CargoXB may give Customer notice, if permitted by applicable law, of any such legal or governmental demand. The Privacy Policy shall only apply to the Platform and the Website and does not apply to any Third-Party Application or other websites, or services linked to the Platform and/or Website or being recommended or referred to through the Platform or by any person at CargoXB.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 4.</div>CargoXB shall not be responsible or take any liability for the accuracy of data uploaded to the Platform by the Customer including, but not limited to Customer Data or any other data, materials or contents uploaded by the User. The Customer agrees to and recognizes the risk of exposure that hosting data online carries with itself including, but not limited to unauthorized disclosure, manipulation, and deletion while accessing and using the Platform. The Customer assumes such risks and CargoXB offers no representation, warranty, or guarantee that Customer Data will not be exposed or disclosed through errors or the actions of third parties.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 5.</div>CargoXB may, with or without notice, permanently erase Customer Data if the Customer’s Account is delinquent, suspended or terminated for more than 30 days. The Customer shall not upload or transmit any data on CargoXB’s Platform which is subjected to a higher degree of protection under the applicable laws.
            </p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 7.</div>INTELLECTUAL PROPERTY RIGHTS</div>

            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>CargoXB retains all titles, rights, and interests in and to the Platform and Products and Services including, but not limited to all software, applications and services used to provide and operate the Platform, including any source code, improvements, enhancements, updates, modifications, or derivative works thereof, whether patentable or not. CargoXB solely owns and retains all rights, titles, and interests in and to the CargoXB Brand. The Customer shall not claim any authority over the Intellectual Property of CargoXB or CargoXB Brand during or after the existence of this Agreement.
            </p>
            <div className='flex body-lg font-semibold'><div className='pr-1'> 8.</div>FEEDBACK</div>

            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>“Feedback” shall refer to any suggestions or ideas for improving or modifying CargoXB’s Platform, Website or any of the Products and Services. CargoXB does not agree to treat any Feedback as confidential and nothing in this Agreement or any other Documentation or any dealing with the Customer or Authorised User shall restrict CargoXB’s right to use, disclose, publish, highlight, hide or otherwise profit out of such Feedback, without any accrual of compensation or credits to the Customer or the User.
            </p>
            <div className='flex body-lg font-semibold'><div className='pr-1'> 9.</div>CONFIDENTIALITY</div>

            <div className='flex body-md text-T06'><div className='pr-1'> 1.</div>Customer shall not use Confidential Information for any purpose other than the use of the Platform, and Products and Services in accordance with the Platform Subscription Agreement.</div>

            <div className='flex body-md text-T06'><div className='pr-1'> 1.</div>The Customer:</div>

            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>Shall only disclose Confidential Information to any agent, employee, or contractor of the Customer on a need-to-know basis, provided, an appropriate non-disclosure agreement has been executed between them;
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>Shall not disclose Confidential Information to any other third party without CargoXB’s prior written consent;
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 3.</div>Shall protect Confidential Information in the manner and with the same degree of care it uses to protect its own Confidential Information of similar nature and significance, but with no less than what is considered reasonable care; and
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 4.</div>Shall promptly notify CargoXB of any misuse or misappropriation of Confidential Information that comes to Customer’s knowledge.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 5.</div>Notwithstanding the foregoing, the Customer may disclose Confidential Information as required by applicable law or by proper legal or governmental authority. The Customer shall give CargoXB prompt notice of any such legal or governmental demand and reasonably cooperate with CargoXB in any effort to seek a protective order or otherwise to contest such required disclosure.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 6.</div>Customer acknowledges and agrees that breach of this Article 9 would cause CargoXB irreparable harm, damage, or injury, for which monetary damages would not provide adequate compensation, and that without prejudice to any other remedy, CargoXB will be entitled to injunctive relief against such breach or threatened breach, without proving actual damage.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 7.</div>This Agreement does not transfer the ownership of any Confidential Information. CargoXB will retain all rights, titles, and interests in and to all Confidential Information.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 8.</div>Throughout the Term of this Agreement, and for a period of 5 (five) years following termination, neither party will use or divulge any Confidential Information of the other party except as provided herein.
            </p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 10.</div>REPRESENTATIONS AND WARRANTIES</div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>Mutual Warranties: Without limiting any other representation, warranty, or covenant herein, each party hereby represents and warrants to the other party that:
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>It has the complete corporate power and authority to enter into this Agreement;
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 3.</div>This Agreement is a valid and binding obligation of such party.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 4.</div>It has obtained and shall maintain throughout the Term of this Agreement all required authorizations, approvals and consents to enter into and perform its obligations hereunder in compliance with all applicable laws, rules, and regulations.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 5.</div>CargoXB represents and warrants that it shall use processes and technology as per industry standards which are designed to prevent attacks on its Platform.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 6.</div>CargoXB shall maintain physical, technical, and administrative safeguards to protect Personal Data.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 7.</div>CargoXB Support: CargoXB shall undertake commercially reasonable efforts to offer and provide support to the Customer to use the Platform and, Products and Services as per Documentation.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 8.</div>Other than the express warranties in Article 10, CargoXB makes no warranty, express or implied, with respect to any matter, including without limitation advertising and other services, and expressly disclaims the implied warranties or conditions of non-infringement, merchantability, and fitness for any specific purpose. CargoXB makes no warranties with respect to the results of or use of the CargoXB Platform or the Website or the Products and Services and the Customer assumes all risk and responsibility with respect thereto.
            </p>

            <div className='flex body-md font-semibold'><div className='pr-1'> 11.</div>INDEMNIFICATION</div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>Customer shall defend, indemnify, and hold harmless CargoXB, and its affiliates from all liabilities, claims, and expenses (including reasonable attorneys’ fees) paid or payable to any third party, that arise from or relate to any third-party claim including but not limited to: (a)allegations that any Customer Data infringes or misappropriates such third party’s Intellectual Property rights or proprietary rights; (b) arising from Customer’s use of the Platform or Products and Services in violation of this Agreement, applicable laws, or any other policies, as amended and made available/notified by CargoXB on their Platform and/or Website.
            </p>

            <div className='flex body-md font-semibold'><div className='pr-1'> 12.</div>LIMITATION OF LIABILITY</div>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 1.</div>CargoXB’s liability arising out of or related to this Agreement shall not exceed the amount actually paid by the Customer as Platform Fees to CargoXB in a period of 3 (three) months immediately preceding the date on which the Customer presents such a claim to CargoXB with respect to a breach of CargoXB’s obligations as herein provided in this Agreement.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 2.</div>Under no circumstances, including, but not limited to, negligence, system failure or network outage, shall CargoXB or its affiliates be liable for any special, indirect, incidental, consequential, punitive, reliance, or exemplary damages that result from this Agreement, even if such party or its authorized representative has been advised of the possibility of such liability for damages.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 3.</div>CargoXB shall have no liability for any claims, losses or damages arising out of or in connection with Customer’s use of any third-party applications, linked website or the products, services, software or other websites linked in the Platform.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 4.</div>The Platform may provide links (or allow the User to provide links) (the “Linked Websites”) that allow the User to leave the Platform and/or access third-party websites or access the CargoXB Platform through third-party websites and the access of the Linked Websites by the User shall be governed by the terms of service and privacy policy of the applicable Linked Websites. The Linked Websites are not under the control of CargoXB, and it is not responsible for the contents of any Linked Websites, any link contained in a Linked Website, any changes or updates to such sites or the performance or security of such other sites. CargoXB is not responsible for any content of or transmission to or from any Linked Website. CargoXB provides these links only as a convenience or feature of the CargoXB Platform, and the inclusion of any link does not imply endorsement by CargoXB of the Linked Websites. Users may access any such third-party websites or access the Platform through any third-party websites at the sole risk and discretion of the User.
            </p>
            <p className='flex mb-0 body-md text-T06'><div className='pr-1'> 5.</div>If applicable law limits the application of the provisions of Article 12, the liability of CargoXB will be limited to the maximum extent permissible. For the removal of any doubt, CargoXB’s liability limits and other rights set forth in Article 12 apply likewise to CargoXB’s affiliates, suppliers, advertisers, agents, sponsors, directors, officers, employees, consultants, and other representatives.
            </p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 13.</div>TERM AND TERMINATION</div>
            <p className='flex mb-0 body-md text-T06'><li>The term “Term” shall have the meaning prescribed in the part of the definition given above.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>The Customer may terminate the Agreement by giving 30 (thirty) days prior written notice to CargoXB.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Either party may terminate this Agreement or Platform Subscription Agreement if the other party:</li></p>
            <p className='flex mb-0 body-md text-T06'><li>fails to cure any material breach (including, without limitation, Customer's failure to pay the Platform Fees) of this Agreement within 30 (thirty) days after written notice of such breach; or</li></p>
            <p className='flex mb-0 body-md text-T06'><li>ceases operation without a successor; or</li></p>
            <p className='flex mb-0 body-md text-T06'><li>seeks protection under any bankruptcy, receivership, trust deed, creditors arrangement, composition, or comparable proceeding, or if any such proceeding is instituted against such party (and not dismissed within sixty (60) days thereafter).</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Upon termination of this Agreement, CargoXB shall cease Customer’s access to the Platform, and Products and Services and Customer shall discontinue all use of the Platform and delete, destroy, or return all copies of the Documentation in its possession or control. The following provisions will survive termination or expiration of this Agreement:</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Any obligation of the Customer to pay the Platform Fees.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Articles 7 (IP), 8 (Feedback), 9 (Confidential Information), 10 (Representation & Warranties), 11 (Indemnification), 12 (Limitation of Liability), 15 (Publicity), 15 (Miscellaneous) and any other provision of this Agreement that must survive to fulfil its essential purpose.</li></p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 14.</div>PUBLICITY</div>
            <p className='flex mb-0 body-md text-T06'><li>The Customer agrees to permit CargoXB to use Customer’s name, trademarks, and service marks with use cases in order to identify as CargoXB’s Customer on the Website, in CargoXB’s marketing materials or in any other marketing and sales activities, unless that Customer notifies CargoXB in writing of its revocation of such permission.</li></p>

            <div className='flex body-lg font-semibold'><div className='pr-1'> 15.</div>MISCELLANEOUS</div>
            <p className='flex mb-0 body-md text-T06'><li>No Agency CargoXB and Customer are independent contractors, and neither CargoXB nor Customer is an agent, representative or partner of the other. CargoXB and Customer shall each have sole responsibility for all acts and omissions of their respective personnel. Neither party shall have any obligation for any employee-related benefits or withholding taxes applicable to the other party’s personnel performing services pursuant to this Agreement.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Notice Any notices under this Agreement shall be in writing and shall be sent to the email address associated with the account (in the case of Customer) or to <a className='text-text_primary' href='mailto:ojasvita@cargoxb.com'>ojasvita@cargoxb.com</a>(in the case of CargoXB) by electronic mail or nationally recognized express delivery courier service and deemed given upon receipt.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Force Majeure No delay, failure, or default, other than a failure to pay fees when due, will constitute a breach of this Agreement to the extent caused by acts of war, terrorism, hurricanes, earthquakes, other acts of God or of nature, strikes or other labour disputes, riots or other acts of civil disorder, embargoes, or other causes beyond the performing party’s reasonable control.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Severability To the extent permitted by applicable law, the parties hereby waive any provision of law that would render any clause of this Agreement invalid or otherwise unenforceable in any respect. In the event that a provision of this Agreement is held to be invalid or otherwise unenforceable, such provision will be interpreted to fulfil its intended purpose to the maximum extent permitted by applicable law, and the remaining provisions of this Agreement will continue in full force and effect.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>No Waiver Neither party will be deemed to have waived any of its rights under this Agreement by lapse of time or by any statement or representation other than by an authorized representative in an explicit written waiver. No waiver of a breach of this Agreement will constitute a waiver of any other breach of this Agreement.</li></p>
            <p className='flex mb-0 body-md text-T06'><li> Choice of Law & Jurisdiction This Agreement shall be governed by and construed and enforced in accordance with the Laws of India The Parties hereby consent to the exclusive jurisdiction of New Delhi, India.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Conflicts and Inconsistencies. In the event of any conflict between this Agreement and the Platform Subscription Agreement, the terms of the Platform Subscription Agreement shall prevail.</li></p>
            <p className='flex mb-0 body-md text-T06'><li>Amendment CargoXB may amend this Agreement from time to time by posting an amended version on its Website/Platform. Customer’s continued use of the Platform, and Products and Services after such amendment will confirm Customer’s consent thereto. CargoXB may revise the Privacy Policy at any time by posting a new version of either on the Website/Platform, and such a new version will become effective on the date it is posted.</li></p>
            <div className='flex mb-0 body-md text-T06'><li> Entire Agreement This Agreement sets forth the entire agreement of the parties and supersedes all prior agreements (whether in oral or written format), discussions, and negotiations with respect to its subject matter. Neither party has relied upon any such prior or contemporaneous communications.</li></div>
            <div className='flex mb-0 body-md text-T06'><li>Grievance Officer If You have any questions or concerns with respect to this Terms of Use or the Platform or any information contained thereon, You may contact Us by writing to Us at:</li></div>
            <div className='text-T06'>
              <div className='flex mb-0 body-md '><p className='mb-0 body-md me-3'>Grievance Officer:</p>Ojasvita Sharma</div>
              <div className='flex mb-0 body-md'>E-mail ID: <a className='text-text_primary body-md  ms-3' href='mailto:ojasvita@cargoxb.com'> ojasvita@cargoxb.com</a></div>
              <div className='flex mb-0 body-md '><p className='mb-0 body-md me-3'>Address: </p> Gopal Heights, Unit No.1404, Pitampura, Delhi, India.</div>
              <p className='flex mt-10 mb-0 body-md '>Headings The Article headings in this Agreement are intended solely for convenience of reference and shall be given no effect in the construction or interpretation of this Agreement.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
